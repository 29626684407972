.ant-layout {
   .ant-layout-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      background: white;
      padding: 0 0 0 0;
      
      &.scrolled {
         background-color: rgba(255, 255, 255, 0.6);
         box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
         backdrop-filter: blur(6px);
      }

      ul {
         height: 64px;
         background: transparent;
         padding: 0px 30px;
         .homepage-logo {
            display: flex; 
            align-items: baseline;
            height: 20%;

            .navbar-logo {
               display: flex;
               align-items: baseline;
               .greg-token-logo {
                  height: 55px;
                  width: auto;
                  margin-top: 4px;
                  margin-right: 50px;
               }
            }
         }

         .ant-menu-item {
            padding-inline: 10px;
            .ant-menu-title-content {
               font-family: "Nunito", sans-serif;
               font-weight: 600;
               font-size: 16px;
               color: #01385a;
               .connect-button {
                  div {
                     border: 0px;
                     .connect-wallet-btn {
                        width: 100%;
                        height: auto;
                        border-radius: 50px;
                        padding: 8px 40px;
                        background-color: #01385a;
                        color: white;
                        font-family: "Nunito", sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        border: 2px solid #0180b3;
                     }
            
                     .connect-wallet-btn:hover {
                        cursor: pointer;
                        background-color: #2EF5FF;
                        color: #01385a;
                        border: 2px solid #2EF5FF;
                        filter: drop-shadow(0 0 0.1rem #12b6be);                     
                     }
                  }

                  div {
                     .connected-wallet-network-btn {
                        width: 100%;
                        height: auto;
                        border-radius: 50px;
                        padding: 7px 30px;
                        background-color: #01385a;
                        color: white;
                        font-family: "Nunito", sans-serif;
                        font-weight: 200;
                        font-size: 15px;
                        border: 2px solid #01385a;
                     }

                     .connected-wallet-network-btn:hover {
                        cursor: pointer;
                        background-color: #2EF5FF;
                        color: #01385a;
                        border: 2px solid #01385a;
                     }

                     .connected-wallet-btn {
                        width: 100%;
                        height: auto;
                        border-radius: 50px;
                        padding: 5px 20px;
                        background-color: #01385a;;
                        color: white;
                        font-family: "Nunito", sans-serif;
                        font-weight: 200;
                        font-size: 15px;
                        border: 2px solid #01385a;
                     }

                     .connected-wallet-btn:hover {
                        cursor: pointer;
                        background-color: #2EF5FF;
                        color: black;
                        border: 2px solid #01385a;
                     }
                  }
               }

               &:hover {
                  color: #13c08c;
               }

               &:active {
                  color: #13c08c;
               }

               &:focus {
                  color: #13c08c;
               }

               .become-greg-button {
                  .ant-btn {
                     width: 100%;
                     height: auto;
                     border-radius: 50px;
                     padding: 6px 15px;
                     background-color: white;
                     color: #01385a;
                     font-family: "Nunito", sans-serif;
                     font-weight: 600;
                     font-size: 14px;
                     border: 2px solid #01385a;
                     
                     &:hover {
                        background-color: #01385a;
                        color: white;
                        font-family: "Nunito", sans-serif;
                        font-weight: 600;
                        font-size: 14px;
                        border: 2px solid #0180b3;
                     }
                  }
               }

               .join-discord-button {
                  .ant-btn {
                     width: 100%;
                     height: auto;
                     border-radius: 50px;
                     padding: 6px 15px;
                     // background-color: #f6746394;
                     // background-color: #F67563;
                     // background-color: #13c08c56;
                     background-color: white;
                     color: #01385a;
                     font-family: "Nunito", sans-serif;
                     font-weight: 600;
                     font-size: 14px;
                     border: 2px solid #01385a;
                     
                     &:hover {
                        background-color: #01385a;
                        color: white;
                        font-family: "Nunito", sans-serif;
                        font-weight: 600;
                        border: 2px solid #0180b3;
                     }
                  }
               }
            }
         }

         .ant-menu-submenu {
            .ant-menu-submenu-title {
               .ant-menu-title-content {
                  font-family: "Nunito", sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  color: #01385a;

                  &:hover {
                     color: #13c08c;
                  }

                  &:active {
                     color: #13c08c;
                  }

                  &:focus {
                     color: #13c08c;
                  }
               }
            }
         }

         .homepage-connect-wallet {
            display: flex;
            align-items: center; 
         }
      }
   }

   .ant-layout-content {
      padding: 0px;
   }
}