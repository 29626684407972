.footer-container {
   height: 50vh; 
   width: auto; 
   background-repeat: no-repeat !important;
   background-size: cover !important;
   background-color: #134341;
   position: relative;

   .footer-container-overlay {
      background-color: transparent;
      background-image: radial-gradient(at top center, #FFFFFF94 0%, #FFFFFF00 70%);
      opacity: 0.4;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
         width: 100%;
         top: 0;
         left: 0;
         position: absolute;
      
   }

   .footer-row {
      padding: 80px 200px 50px 200px;

      .ant-col-footer-newsletter {
         height: 100%;
         width: auto;
         .footer-newsletter-wrapper {
            background-repeat: no-repeat !important;
            background-size: contain !important;
            background: url("../../public/common/greg-logo.png");
            position: relative;
            height: 80px;
            width: auto;
            margin-bottom: 105px;
         }

         .footer-newsletter-title {
            font-size: 20px;
            font-family: "Nunito", sans-serif;
            font-weight: 400;
            color: #01385a; 
            margin: 20px 0 10px 0;
         }

         .footer-newsletter-btn {
            height: 50px;
            width: 90%;
            .ant-space-item {
               width: 100%;
               .ant-space-compact {
                  // filter: drop-shadow(0 0 0.3rem #62afdb);
                  //filter: drop-shadow(0 0 0.1rem #13c08cc9);
                  .ant-input {
                     width: 100%;
                     height: 50px;
                     background-color: white;
                     border-top-left-radius: 50px;
                     border-bottom-left-radius: 50px;
                     font-size: 20px;
                     font-family: "Nunito", sans-serif;
                     font-weight: 200;
                     font-style: italic;
                     padding-left: 25px;
                     padding-bottom: 6px;
                     border: 1px solid white;
                     box-shadow: none;
                  }

                  .ant-input::placeholder {
                     font-size: 21px;
                     font-family: "Nunito", sans-serif;
                     font-weight: 400;
                     font-style: italic;
                     color: rgba(23, 19, 61, 0.3);
                  }

                  .ant-input-outlined:focus-within {
                     border: 1px solid white;                  
                  }

                  .ant-btn {
                     width: 20%;
                     height: 50px;
                     background-color: white;
                     border-top-right-radius: 50px;
                     border-bottom-right-radius: 50px;
                     display: flex;
                     align-items: center;
                     border: 0px;
                     svg {
                        height: 50px;
                        width: auto;
                        fill: #01385a;
                        position: sticky;
                        top: 50%;  
                     }
                  }
               }
            }
         }
      }

      .ant-col-footer-sitemap {
         text-align: left;
         padding: 0 0px 0 200px;

         .ant-row {
            .footer-sitemap-title {
               color: #01385a;
               font-size: 25px;
               font-family: "Nunito", sans-serif;
               font-weight: 600;
               padding-left: 0px!important;
            }

            .footer-sitemap-link {
               color: white!important;
               font-size: 20px;
               font-family: "Nunito", sans-serif;
               font-weight: 400;
               .footer-sitemap-link-text {
                  color: white!important;
                  font-size: 20px;
                  font-family: "Nunito", sans-serif;
                  font-weight: 400;
               }

               .footer-sitemap-link-text:hover {
                  color: #01385a!important;
                  font-size: 20px;
                  font-family: "Nunito", sans-serif;
                  font-weight: 400;
               }
            }
         }

         .footer-row-media {
            margin-top: 50px;
            width: 40%;
            display: flex; 
            justify-content: flex-end;
            
            .footer-col-media {
               width: 50px;
               .ball {
                  display: inline-block;
                  width: 60px;
                  height: 60px;
                  margin: 0;
                  border-radius: 50%;
                  position: relative;
                  // background: radial-gradient(circle at 50% 120%, #13c08c, #0ba073 10%, #006d4c 80%, #002841 100%);
                  // #01385a
                  background: radial-gradient(circle at 50% 120%, #117abb, #0c4e77 10%, #01385a 80%, #002841 100%);
               }

               .ball:before {
                  content: "";
                  position: absolute;
                  top: 1%;
                  left: 5%;
                  width: 90%;
                  height: 90%;
                  border-radius: 50%;
                  background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 58%);
                  filter: blur(1px);
                  z-index: 2;
               }

               .ball .shadow {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background: radial-gradient(circle at 50% 50%, rgb(255, 254, 254), rgb(241, 237, 237) 40%, rgb(255, 254, 254) 50%);
                  -webkit-transform: rotateX(90deg) translateZ(-150px);
                  -moz-transform: rotateX(90deg) translateZ(-150px);
                  -ms-transform: rotateX(90deg) translateZ(-150px);
                  -o-transform: rotateX(90deg) translateZ(-150px);
                  transform: rotateX(90deg) translateZ(-150px);
                  z-index: -1;
               }

               .ball  .media-icon {
                  position: absolute;
                  height: 30px;
                  width: auto;
                  fill: white;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
               }
            }
         }
      }
   }
}